import _ from 'lodash';
import { useEffect } from 'react';

import {
  LayoutSingleColumn,
  ListingCard,
  MobileBottomNavigation,
  NamedLink,
  Page,
  PrimaryButton,
} from '../../components';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FollowingSection from './components/FollowingSection/FollowingSection';
import HeroCollectionSection from './components/HeroCollectionSection/HeroCollectionSection';
import ListingsSection from './components/ListingsSection/ListingsSection';
import ShowcaseCarousel from './components/ShowcaseCarousel/ShowcaseCarousel';
import css from './CustomLandingPage.module.css';
import { ConditionType } from 'config/configListing';
import { OurCommitments } from 'containers/ListingPage/ListingPage';
import { PIFERI_BRAND_ID } from 'containers/PiferiLandingPage/PiferiLandingPage';
import { useGetFavouriteListings } from 'hooks/api/listings/useGetFavouriteListings';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { DEFAULT_SORTING } from 'util/constants';
import { sendGa4Event } from 'util/GA4Events';

const CustomLandingPage = () => {
  const scrollingDisabled = useIsScrollingDisabled();

  useEffect(() => {
    sendGa4Event('home_page_visited', {});
  }, []);

  const { data: listingsData } = useGetListings({});
  const allListings = listingsData?.pages.flatMap(page => page.listings) || [];
  const latestListings = allListings.slice(0, 24);

  const { data: sampleListingsData } = useGetListings({
    condition: ConditionType.SampleSale,
    availability: 'hide_sold',
  });
  const sampleListings = _.sampleSize(
    sampleListingsData?.pages.flatMap(page => page.listings) || [],
    24
  );

  const { data: favouritesData } = useGetFavouriteListings({});

  const favouriteListings = favouritesData?.pages.flatMap(page => page.listings) || [];

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<MobileBottomNavigation />}>
        <div className={css.wrapper}>
          <FollowingSection />
          {/* <HeroCollectionSection /> */}
          <ShowcaseCarousel
            title={
              <span>
                OUR BIGGEST <br className="md:hidden" /> SAMPLE SALE
              </span>
            }
            // itemClassName={css.showCaseItem__sampleSales}
            itemClassName={css.showCaseItem__collections}
            items={[
              {
                title: <span className="text-white">Piferi</span>,
                imgSrc: 'https://cdn.thenold.com/images/samplesale_31july_4_compressed.webp',
                imgBlurSrc: 'https://cdn.thenold.com/images/samplesale_31july_4_blur.webp',
                button: (
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: `brandIds=${PIFERI_BRAND_ID}&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
                    }}
                  >
                    <PrimaryButton>Shop now</PrimaryButton>
                  </NamedLink>
                ),
              },
              {
                title: 'Pangaia',
                imgSrc: 'https://cdn.thenold.com/images/samplesale_31july_5_compressed.webp',
                imgBlurSrc: 'https://cdn.thenold.com/images/samplesale_31july_5_blur.webp',
                button: (
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: `brandIds=2885&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
                    }}
                  >
                    <PrimaryButton>Shop now</PrimaryButton>
                  </NamedLink>
                ),
              },
              {
                title: 'By Far',
                imgSrc: 'https://cdn.thenold.com/images/samplesale_31july_6_compressed.webp',
                imgBlurSrc: 'https://cdn.thenold.com/images/samplesale_31july_6_blur.webp',
                button: (
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: `brandIds=2854&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
                    }}
                  >
                    <PrimaryButton>Shop now</PrimaryButton>
                  </NamedLink>
                ),
              },
            ]}
          />
          {Boolean(sampleListings?.length) && (
            <ListingsSection title="Shop all">
              {sampleListings.map(l => (
                <ListingCard key={l.id.uuid} listing={l} />
              ))}
            </ListingsSection>
          )}
          <ShowcaseCarousel
            title="Top Collections"
            items={[
              {
                title: 'Bags',
                imgSrc: 'https://cdn.thenold.com/home/BAGS.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/BAGS.blur.jpg',
                linkParams: {
                  name: 'SearchPage',
                  to: { search: `category=bags&sortBy=${DEFAULT_SORTING}` },
                },
              },
              {
                title: 'Clothes',
                imgSrc: 'https://cdn.thenold.com/home/CLOTHES.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/CLOTHES.blur.jpg',
                linkParams: {
                  name: 'SearchPage',
                  to: { search: `category=clothing&sortBy=${DEFAULT_SORTING}` },
                },
              },
              {
                title: 'Accessories',
                imgSrc: 'https://cdn.thenold.com/home/ACCESSORIES.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/ACCESSORIES.blur.jpg',
                linkParams: {
                  name: 'SearchPage',
                  to: { search: `category=accessories&sortBy=${DEFAULT_SORTING}` },
                },
              },
              {
                title: 'Shoes',
                imgSrc: 'https://cdn.thenold.com/home/SHOES.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/SHOES.blur.jpg',
                linkParams: {
                  name: 'SearchPage',
                  to: { search: `category=shoes&sortBy=${DEFAULT_SORTING}` },
                },
              },
            ]}
            itemClassName={css.showCaseItem__collections}
          />
          <ShowcaseCarousel
            title="Icons"
            itemClassName={css.showCaseItem__icons}
            items={[
              {
                title: (
                  <span>
                    MARIA <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                    <br />
                    THE NOLD
                  </span>
                ),
                imgSrc: 'https://cdn.thenold.com/home/icons/maria.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/icons/maria.blur.jpg',
                linkParams: {
                  name: 'ProfilePage',
                  params: {
                    id: '660aeed8-a6fe-4871-98c2-3f1d96d43a87',
                  },
                },
              },
              {
                title: (
                  <span style={{ color: 'var(--n-color-black)' }}>
                    MOLLIE{' '}
                    <span
                      style={{ fontSize: 'var(--n-font-size-3)', color: 'var(--n-color-black)' }}
                    >
                      x
                    </span>
                    <br />
                    THE NOLD
                  </span>
                ),
                imgSrc: 'https://cdn.thenold.com/home/icons/mollie.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/icons/mollie.blur.jpg',
                linkParams: {
                  name: 'ProfilePage',
                  params: {
                    id: '66053581-9a48-4b01-824b-9f365dc920b1',
                  },
                },
              },
              {
                title: (
                  <span>
                    IVONA <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                    <br />
                    THE NOLD
                  </span>
                ),
                imgSrc: 'https://cdn.thenold.com/home/icons/ivona.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/icons/ivona.blur.jpg',
                linkParams: {
                  name: 'ProfilePage',
                  params: {
                    id: '6603fac8-062f-43da-81e0-f7a1497e592b',
                  },
                },
              },
              {
                title: (
                  <span>
                    DANIELLE <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                    <br />
                    THE NOLD
                  </span>
                ),
                imgSrc: 'https://cdn.thenold.com/home/icons/danielle.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/icons/danielle.blur.jpg',
                linkParams: {
                  name: 'ProfilePage',
                  params: {
                    id: '65f2fe35-15e2-4fa0-a8ac-f4a162dbe486',
                  },
                },
              },
              {
                title: (
                  <span>
                    LILY <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                    <br />
                    THE NOLD
                  </span>
                ),
                imgSrc: 'https://cdn.thenold.com/home/icons/lily.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/icons/lily.blur.jpg',
                linkParams: {
                  name: 'ProfilePage',
                  params: {
                    id: '65f86f23-33af-4593-ac87-81867cc58e6e',
                  },
                },
              },
              {
                title: (
                  <span>
                    AMELIA <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                    <br />
                    THE NOLD
                  </span>
                ),
                imgSrc: 'https://cdn.thenold.com/home/icons/amelia.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/home/icons/amelia.blur.jpg',
                linkParams: {
                  name: 'ProfilePage',
                  params: {
                    id: '65f9106d-fdf0-4394-a538-9835daf49c95',
                  },
                },
              },
            ]}
          />
          {Boolean(latestListings?.length) && (
            <ListingsSection title="Recently added listings">
              {latestListings.map(l => (
                <ListingCard key={l.id.uuid} listing={l} />
              ))}
            </ListingsSection>
          )}
          {Boolean(favouriteListings?.length >= 3) && (
            <ListingsSection title="Your Wishlist">
              {favouriteListings.map(l => (
                <ListingCard key={l.id.uuid} listing={l} />
              ))}
            </ListingsSection>
          )}
          {/* TODO: Copy component */}
          <OurCommitments />
        </div>
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

export default CustomLandingPage;
