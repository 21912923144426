import classNames from 'classnames';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';

import {
  LayoutSingleColumn,
  ListingCard,
  ListingsCarousel,
  MobileBottomNavigation,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  Page,
  SingleProductGallery,
  SingleProductGalleryModal,
} from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import {
  createListingSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './ListingPage.module.css';
import { ErrorPage, listingImages, LoadingPage, priceData } from './ListingPage.shared';
import { ConditionType } from 'config/configListing';
import { sizeStandardToSchemaStandard } from 'containers/EditListingPage/EditListingWizard/EditListingCategoryAndSize/productSizingStandards';
import {
  adultDepartments,
  maleDepartments,
} from 'containers/EditListingPage/EditListingWizard/EditListingCategoryAndSize/productTypeDefinitions';
import { ListingEditTab } from 'containers/EditListingPage/EditListingWizard/EditListingWizardTab';
import { useGetFavouriteListings } from 'hooks/api/listings/useGetFavouriteListings';
import { useGetListing } from 'hooks/api/listings/useGetListing';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { sendGa4Event } from 'util/GA4Events';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [showModal, setShowModal] = useState(false);

  const { data: listings } = useGetListings({ availability: 'hide_sold' });
  const suggestedListings = listings?.pages.flatMap(page => page.listings) || [];

  const { data: favouritesData } = useGetFavouriteListings({});

  const favouriteListings = favouritesData?.pages.flatMap(page => page.listings) || [];

  const {
    currentUser,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    config,
  } = props;

  const [photosShown, setPhotosShown] = useState<'seller' | 'original'>('seller');
  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingId = new UUID(rawParams.id);

  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const { data: listingData } = useGetListing(listingId, {
    isOwn: isPendingApprovalVariant || isDraftVariant,
  });
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(listingData)
      : ensureListing(listingData);

  const listingSlug = rawParams.slug || createListingSlug(currentListing);
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab: ListingEditTab = 'photos';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  const isDeleted = currentListing.id && currentListing.attributes.publicData?.isDeleted;
  const isClosed = currentListing.id && currentListing.attributes.state === 'closed';
  const isManuallySold = currentListing.id && currentListing.attributes.publicData?.isManuallySold;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  useEffect(() => {
    sendGa4Event('product_page_visited', {});
  }, []);

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (
    (showListingError && showListingError.status === 404) ||
    isDeleted ||
    (isClosed && !isOwnListing)
  ) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const { price = null } = currentListing.attributes;
  const title = [currentListing.attributes.title, currentListing.attributes.publicData?.brandName]
    .filter(Boolean)
    .join(' - ');
  const description = `Buy ${title} on THE NOLD. ${
    currentListing.attributes.publicData?.description || ''
  }`;
  const forAdults = adultDepartments.includes(currentListing.attributes.publicData?.department);

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );

  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: false,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const originalImagesLength =
    currentListing?.attributes?.publicData?.originalPhotoIds?.length || 0;
  const hasOriginalPhotos = originalImagesLength > 0;

  const onOpenModal = () => {
    setShowModal(true);
  };

  function formatSchemaString(input: string | undefined): string {
    let formattedString = input?.replace(/-/g, ' ') || '';
    if (formattedString.length > 0) {
      formattedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
    }
    return formattedString;
  }

  const conditionSchema =
    currentListing.attributes.publicData.condition === ConditionType.SampleSale
      ? 'https://schema.org/NewCondition'
      : 'https://schema.org/UsedCondition';

  const categorySchema = formatSchemaString(currentListing.attributes.publicData.category);
  const productTypeSchema = formatSchemaString(currentListing.attributes.publicData.productType);

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        category: categorySchema,
        additionalType: productTypeSchema,
        brand: currentListing.attributes.publicData?.brandName,
        color: currentListing.attributes.publicData?.colors?.join(', '),
        size: {
          '@type': 'SizeSpecification',
          name: currentListing.attributes.publicData?.size?.toUpperCase(),
          sizeSystem: sizeStandardToSchemaStandard(
            currentListing.attributes.publicData?.sizeStandard || ''
          ),
        },
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          itemCondition: conditionSchema,
          availability: schemaAvailability,
        },
        audience: {
          '@type': 'Audience',
          // https://support.google.com/merchants/answer/6386198
          // https://support.google.com/merchants/answer/6324463
          suggestedMinAge: forAdults ? 13 : 5,
          suggestedMaxAge: forAdults ? undefined : 13,
          suggestedGender: maleDepartments.includes(
            currentListing.attributes.publicData?.department
          )
            ? 'male'
            : 'female',
        },
        identifier_exists: 'no',
      }}
    >
      <Helmet>
        <title>{schemaTitle}</title>
        <meta name="og:title" content={schemaTitle} />
        <meta name="twitter:title" content={schemaTitle} />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={topbar}
        footer={<MobileBottomNavigation />}
      >
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {hasOriginalPhotos && (
              <div className={css.originalSellerLabel}>
                <span
                  aria-selected={photosShown === 'seller'}
                  onClick={() => setPhotosShown('seller')}
                >
                  Photos
                </span>
                <span
                  aria-selected={photosShown === 'original'}
                  onClick={() => setPhotosShown('original')}
                >
                  Original Photos
                </span>
              </div>
            )}
            <SingleProductGallery
              currentListing={currentListing}
              onOpenModal={onOpenModal}
              photosFilter={photosShown}
            />
            <SingleProductGalleryModal
              open={showModal}
              onOpenChange={setShowModal}
              currentListing={currentListing}
              photosFilter={photosShown}
            />
          </div>
          <div
            className={css.orderColumnForProductLayout}
            style={{ marginTop: hasOriginalPhotos ? 40 : 0 }}
          >
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingPathParamType,
                tab: listingTab,
              }}
              status={
                isPendingApprovalVariant
                  ? 'Pending approval'
                  : isDraftVariant
                  ? 'Draft'
                  : isClosed
                  ? 'Closed'
                  : isManuallySold
                  ? 'Marked as Sold'
                  : currentStock < 1
                  ? 'Requested by another user'
                  : null
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              monthlyTimeSlots={monthlyTimeSlots}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              listingSlug={listingSlug}
            />
          </div>
        </div>
        {suggestedListings?.length > 0 && (
          <div className={css.sectionWrapper}>
            <div className={css.sectionTitle}>
              <FormattedMessage id="ListingPage.similarItemsLabel" />
            </div>
            <ListingsCarousel>
              {suggestedListings &&
                suggestedListings.map(l => <ListingCard key={l?.id?.uuid} listing={l} />)}
            </ListingsCarousel>
          </div>
        )}
        {favouriteListings?.length > 0 && (
          <div className={css.sectionWrapper}>
            <div className={css.sectionTitle}>Your Wishlist</div>
            {/* TODO: Get wishlist cards */}
            <ListingsCarousel>
              {favouriteListings.map(l => (
                <ListingCard key={l?.id?.uuid} listing={l} />
              ))}
            </ListingsCarousel>
          </div>
        )}
        <OurCommitments />
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

export const OurCommitments = () => {
  return (
    <div className={classNames(css.sectionWrapper, css.sectionCommitments)}>
      <div className={css.sectionTitle}>WHY THE NOLD</div>
      <div className={css.sectionCommitments__content}>
        <div>
          <h3>AI LISTING</h3>
          <p>
            The NOLD AI helps you by generating a title, description and doing all of the
            categorising for you, making the listing 85% faster than any other platform.
          </p>
        </div>
        <div>
          <h3>100% PAYOUT</h3>
          <p>
            Enjoy claiming 90% of your sales in cash or 100% in store credit towards Harvey Nichols,
            Zalando, Adidas, Nike, Sainsbury’s, Waitrose, Uber, Airbnb, and more.
          </p>
        </div>
        <div>
          <h3>ICONIC WARDROBES</h3>
          <p>
            We aspire to offer the most exciting and exclusive curation in the universe, so we work
            with the finest icons to open up their wardrobes for some die-for pieces!
          </p>
        </div>
      </div>
    </div>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  // @ts-expect-error TS(2339) FIXME: Property 'route' does not exist on type '{}'.
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  // @ts-expect-error TS(2339) FIXME: Property 'currentUser' does not exist on type '{}'... Remove this comment to see the full error message
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type '{}'.
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  // @ts-expect-error TS(2339) FIXME: Property 'review' does not exist on type '{}'.
  reviews: arrayOf(propTypes.review),
  // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type '{}'.
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type '{}'.
  sendInquiryError: propTypes.error,
  listingConfig: object,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type '{}'.
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
